import moment from "moment"

export  const TypeSelector = (type, value, checked) => {
  switch (type) {
    case 'DatePicker':
      return value ? moment(value).format("MM/DD/YYYY") : ""
    case 'Check':
      return checked ? "1" : "0";
    default:
      return value;
  }
};