import React, { Component } from "react"
import Layout from "../components/Layout/layout"
import Wrapper from "../components/Layout/Wrapper/wrapper"
import "../components/scss/text.scss"
import MultiStepForm from '../components/Form/MultiStepForm/index'

class Form extends Component {

  render() {
    return (
      <Layout headerTitle={this.props.pageContext.headerTitle ? this.props.pageContext.headerTitle : undefined} hideMenuButton={false}>
        <Wrapper>
          <MultiStepForm json={this.props.pageContext.json} />
        </Wrapper>
      </Layout>
    )
  }
}

export default Form