import React from "react"
import "./textArea.scss"
import Tooltip from "../Tooltip/tooltip";

const TextArea = props => {
    const classStyle = `${props.validate ? "e-text" : "e-text is-invalid"} ${props.class ? props.class : ""}`;
    const dataQA = `${props.type}-${props.name}`;
    return (
        <div className={classStyle}>
            <span className="e-text-error">{props.errorMessage}</span>
            <textarea
                id={props.name}
                className="e-text-input"
                name={props.name}
                placeholder={props.placeholder}
                onChange={props.onChange}
                value={props.value}
                data-qa={dataQA}
            />
            <div className="inputLabel">{props.label}</div>
            {props.tooltip &&
            <Tooltip
                position="left"
                effect="solid"
                event="click"
                tip={props.name}
                dataFor={`m-tooltip__${props.name}`}
                content={props.tooltip}/>
            }
        </div>
    )
}

export default TextArea;

