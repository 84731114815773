import endpointDefinition from '../../../internals/api/orders/post_orders.json';
import { AjaxAction } from 'jsr-lib-http/dist/esm/AjaxAction';


export class CreateOrderAction extends AjaxAction{

    constructor (endpointService) {
        super(endpointService, endpointDefinition);
    }

}
