import { EditApplicationAction } from './EditApplicationAction';
import { AjaxHandler } from 'jsr-lib-http/dist/cjs/AjaxHandler';
import { LoggerService } from '../../logger/LoggerService';
import { ErrorHandle } from '../../errors/ErrorHandle';
import { EditApplicationReducer } from './EditApplicationReducer';

export class EditApplicationHandler extends AjaxHandler {

    constructor (options) {
        options = options || {};
        super({
            apiPrefix: process.env.API_PREFIX,
            action: EditApplicationAction,
            reducer: options.reducer || EditApplicationReducer,
            loggerService: LoggerService,
            errorHandler: options.errorHandle || ErrorHandle.default,
            httpParams : {
                authorization : process.env.HTTP_SECRET_KEY,
                headers: {
                    'Accept-language': 'es'
                  }
            },
        });
    }

}
