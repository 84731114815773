import React from "react"
import "./check.scss"
import Tooltip from "../Tooltip/tooltip"


export const Check = props => {
  const dataQA = `${props.type}-${props.name}`
  const classStyle = `${props.validate ? "e-check" : "e-check is-invalid"} ${props.class ? props.class : ""}`
  return (
    <div className={classStyle}>
      <input
        className="e-check-input"
        type="checkbox"
        name={props.name}
        id={props.name}
        onChange={props.onChange}
        value={props.value === "1" ? props.value : "0"}
        checked={props.value === "1"}
        data-qa={dataQA}
      />
      <label htmlFor={props.name}>
        <span dangerouslySetInnerHTML={{__html: props.label}}/>
      </label>
      {props.tooltip &&
      <Tooltip
        position="left"
        effect="solid"
        event="click"
        tip={props.name}
        dataFor={`m-tooltip__${props.name}`}
        content={props.tooltip}/>
      }
    </div>
  );
}

{/*<div className={classStyle}>*/
}
{/*  <input*/
}
{/*    className="e-check-input"*/
}
{/*    type="checkbox"*/
}
{/*    name={props.name}*/
}
{/*    id={props.name}*/
}
{/*    onChange={props.onChange}*/
}
{/*    value={props.value === "1" ? props.value : "0"}*/
}
{/*    checked={props.value === "1"}*/
}
{/*    data-qa={dataQA}*/
}
{/*  />*/
}
{/*  {!props.hiddenCheck &&*/
}
{/*  <label htmlFor={props.name}>*/
}
{/*    <span dangerouslySetInnerHTML={{ __html: labelCheck }}/>*/
}
{/*    {props.link ?*/
}
{/*      <a href={props.link} rel="noopener noreferrer" target="_blank"> {props.linkLabel}</a> : null}*/
}
{/*  </label>*/
}
{/*  }*/
}
{/*  {props.labelLegal && <span className='spanTerms' dangerouslySetInnerHTML={{ __html: labelTerms }}/>}*/
}
{/*  {props.tooltip &&*/
}
{/*  <Tooltip*/
}
{/*    position="left"*/
}
{/*    effect="solid"*/
}
{/*    event="click"*/
}
{/*    tip={props.name}*/
}
{/*    dataFor={`m-tooltip__${props.name}`}*/
}
{/*    content={props.tooltip}/>*/
}
{/*  }*/
}
{/*  {props.modals &&*/
}
{/*  props.modals.map(modal => {*/
}
{/*    if (this.state.modalsVisibility[modal.modalLinkRef]) {*/
}
{/*      return (*/
}
{/*        <div id={modal.modalLinkRef} className="modal-window" key={modal.modalLinkRef}>*/
}
{/*          <div>*/
}
{/*                  <span title="Close" onClick={() => this.toggleModal(modal.modalLinkRef, false)}*/
}
{/*                        className="modal-close">Close</span>*/
}
{/*            <div className='legal-message'>*/
}
{/*              {modal.modalText}*/
}
{/*            </div>*/
}
{/*          </div>*/
}
{/*        </div>*/
}
{/*      )*/
}
{/*    }*/
}
{/*  })*/
}
{/*  }*/
}
{/*</div>*/
}


export default Check