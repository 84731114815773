import React from "react"
import "./radio.scss"
import Tooltip from "../Tooltip/tooltip";

export const Radio = props => {
  const dataQA = `${props.type}-${props.name}`;
  const classStyle = `${props.validate ? "m-form-group m-form-switch e-radio" : "m-form-group m-form-switch e-radio is-invalid"} ${props.class ? props.class : ""}`;
  return (
    <div className={classStyle}>
      <label className="main-label" htmlFor={props.name}>
        {props.label}
        {props.disclaimer &&
        <div className={`e-radio-disclaimer small`}>
          {props.disclaimer}
        </div>}
        {props.link ?
          <a href={props.link} rel="noopener noreferrer" target="_blank"> {props.label}</a> : null}
      </label>
      <div className='e-radio-wrapper'>
        {props.items.map(radioItem => {
          return (
            <label className="e-radio-label" key={radioItem.value}>
              <div className="e-radio-container"  id={`${props.name}`}>
                <input
                  className="e-radio-input"
                  type="radio"
                  id={`${props.name}_${radioItem.value}`}
                  name={props.name}
                  onChange={props.onChange}
                  value={radioItem.value}
                  checked={radioItem.value === props.value}
                  data-qa={dataQA}
                />
                <label htmlFor={`${props.name}_${radioItem.value}`}>{radioItem.label}</label>
                <div className="e-radio-help">{props.help}</div>
                {props.tooltip &&
                <Tooltip
                  position="left"
                  effect="solid"
                  event="click"
                  tip={props.name}
                  dataFor={`m-tooltip__${props.name}`}
                  content={props.tooltip}/>
                }
              </div>
            </label>

          )
        })}
      </div>
      <span className="e-text-error">{props.errorMessage}</span>
    </div>
  )
}