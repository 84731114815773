import React, { useEffect, useState } from "react"
import "./select.scss"
import Tooltip from "../Tooltip/tooltip"

const Select = props => {
  const initialColor = props.value ? "regular-options" : "grey-option"
  const [color, setColor] = useState(initialColor)
  useEffect(() => {
    if (props.value) setColor("regular-options")
  })

  const options = props.items
  const dataQA = `${props.type}-${props.name}`
  const classStyle = `${props.validate ? "e-select" : "e-select is-invalid"} ${props.class ? props.class : ""} e-${props.type} ${props.validField ? "is-valid" : ""}`
  return (
    <div className={classStyle}>
      <span className="e-select-error">{props.errorMessage}</span>
      <select onClick={() => setColor("regular-options")} data-qa={dataQA} id={props.name} name={props.name}
              onChange={props.onChange} className={"e-select-input " + color} value={props.value && props.value}>
        <option value="" disabled>{props.placeholder}</option>
        {Object.keys(options).map((keyName, i) => (
          <option
            className='regular-options'
            value={options[keyName].value}
            key={i}
          >
            {options[keyName].name}
          </option>
        ))}
      </select>
      <div className="inputLabel">{props.label}</div>
      {props.tooltip &&
      <Tooltip
        position="left"
        effect="solid"
        event="click"
        tip={props.name}
        dataFor={`m-tooltip__${props.name}`}
        content={props.tooltip}/>
      }
    </div>
  )
}
export default Select