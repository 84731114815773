import {EncodedCookieDataStore} from "../storage/datastore/EncodedCookieDataStore";
import {Base64DecoderService} from "../storage/decoder/Base64DecoderService";
import {Base64EncoderService} from "../storage/encoder/Base64EncoderService";

export const checkUtmParams = () => {

  const utmCookie = new EncodedCookieDataStore({
    cookieName: 'utm_param',
    decoderService: new Base64DecoderService(),
    encoderService: new Base64EncoderService(),
  });

  const UTMS = ['utm_source', 'utm_campaign', 'utm_medium'];

  function getUrlParams() {
    let params = {};
    if (typeof window !== 'undefined') {
      let parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
        params[key] = value;
      });
    }
    return params;
  }

  if (getUrlParams()['utm_medium']) {
    for (let utm of UTMS) {
      utmCookie[utm] = getUrlParams()[utm];
    }
  }
};
