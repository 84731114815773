import {Radio} from '../Form/Radio';
import TextInput, {InputMaskForm} from "../Form/TextInput"
import Select from "../Form/Select"
import Check from "../Form/Check"
import {Button} from "../Layout/Buttons/buttons"
import DatePicker from "./DatePicker"
import TextArea from "./TextArea/index"

const FormComponents = {
  Radio: Radio,
  TextInput: TextInput,
  Select: Select,
  Check: Check,
  Button: Button,
  DatePicker: DatePicker,
  InputMask:InputMaskForm,
  TextArea:TextArea
};

export default FormComponents
