import React, { Component } from "react"
import { isInclusivelyBeforeDay, SingleDatePicker } from "react-dates"
import "react-dates/lib/css/_datepicker.css"
import moment from "moment"
import "react-dates/initialize"
import "./datepicker.scss"

export default class DatePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      focused: props.focused,
      date: props.value === "" ? null : moment(props.value, 'MM-DD-YYYY'),
      className: ``,
    }
  }

  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) =>
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ marginRight: "5px", width: "40%" }}>
        <select className={`e-select-input`}
                value={month.month()}
                onChange={(e) => onMonthSelect(month, e.target.value)}
                style={{ width: "100%" }}>
          {moment.months().map((label, value) => (
            <option key={value} value={value}>{label}</option>
          ))}
        </select>
      </div>
      <div style={{ width: "25%" }}>
        <select className={`e-select-input`} value={month.year()}
                onChange={(e) => onYearSelect(month, e.target.value)}
                style={{ width: "100%" }}>
          {this.returnYears()}
        </select>
      </div>
    </div>

  returnYears = () => {
    let years = [],
      minYear = moment().year() - 100,
      maxYear = this.props.maxElegibleDate === "today" ? moment().year() : moment().year() + 5

    for (let i = minYear; i <= maxYear; i++) {
      years.push(<option key={i} value={i}>{i}</option>)
    }
    return years
  }

  componentDidMount() {
    if (this.props.value) this.setState({}, () => {
      this.setState({ date: this.props.value })
    })
  }

  render() {
    var minElegibleDate = this.props.minElegibleDate === "" ? null : moment(this.props.minElegibleDate)
    const maxElegibleDateFunc = () => {
      if (this.props.maxElegibleDate === "") return moment().add("5", "year")
      else if (this.props.maxElegibleDate === "today") return moment()
      else return moment(this.props.maxElegibleDate)
    }
    var maxElegibleDate = maxElegibleDateFunc();
    const dataQA = `${this.props.type}-${this.props.name}`
    let classStyle = `${this.props.validate ? "e-text" : "e-text is-invalid"} ${this.props.class ? this.props.class : ""} e-${this.props.type} ${this.props.value ? "is-valid" : ""}`

    return (
      <div data-qa={dataQA} className={`${classStyle} ${this.state.className} `}>
        {this.props.disclaimer &&
        <div className={`e-radio-disclaimer small`}>
          {this.props.disclaimer}
        </div>}
        <span className="e-text-error">{this.props.errorMessage}</span>
        <SingleDatePicker
          date={this.props.value === "" ? null : moment(this.props.value, 'MM-DD-YYYY')}
          showClearDate={true}
          onDateChange={date => {
            this.setState({ date }, () => {
              let dateObject = {
                target: {
                  name: this.props.name,
                  value: this.state.date,
                },
              }
              this.props.onChange(dateObject)
            })
            this.setState({ className: "is-valid" })
          }}
          focused={this.state.focused}
          onFocusChange={({ focused }) => {
            this.setState({ focused })

          }}
          numberOfMonths={this.props.monthToShow || 1}
          hideKeyboardShortcutsPanel={true}
          isOutsideRange={day => {
            if (this.props.maxElegibleDate === "today") maxElegibleDate = moment()
            else if (this.props.minElegibleDate === "today") minElegibleDate = moment()
            return day.isAfter(maxElegibleDate) || day.isBefore(minElegibleDate)
          }
          }
          enableOutsideDays={false}
          id={this.props.name}
          renderMonthElement={this.renderMonthElement}
          placeholder={`Please select a date`}
          readOnly
        />
        <div className="inputLabel">{this.props.label}</div>
      </div>
    )
  };
}