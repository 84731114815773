import React, { Component } from "react"
import StepWizard from "react-step-wizard"
import Nav from "./Nav/index"
import MainFormSteps from "../MainFormSteps/index"
import { CreateOrderHandler } from "../../../services/api/CreateOrder/CreateOrderHandler"
import { EncodedCookieDataStore } from "../../../services/storage/datastore/EncodedCookieDataStore"
import { Base64EncoderService } from "../../../services/storage/encoder/Base64EncoderService"
import { Base64DecoderService } from "../../../services/storage/decoder/Base64DecoderService"
import { GetOrderHandler } from "../../../services/api/GetOrder/GetOrderHandler"
import { Loading } from "../../Modules/Loading/loading"
import { TypeSelector } from "../../../services/componentSwitcher/TypeSelector"
import { TypeSelectorValidate } from "../../../services/componentSwitcher/TypeSelectorValidate"
import { EditApplicationHandler } from "../../../services/api/EditApplication/EditOrderHandler"
import moment from "moment"
import {checkUtmParams} from "../../../services/sem/checkUtmParams"

class MultiStepForm extends Component {

  constructor(props) {
    super(props)

    const getOrder = new EncodedCookieDataStore({
      cookieName: "address_token",
      decoderService: new Base64DecoderService(),
      encoderService: new Base64EncoderService(),
    })
    const cookieUtm = new EncodedCookieDataStore({
      cookieName: 'utm_param',
      decoderService: new Base64DecoderService(),
      encoderService: new Base64EncoderService(),
    })
    this.state = {
      cookie: cookieUtm,
      form: this.props.json.fields,
      pdfId: getOrder.pdfId,
      structure: this.props.json.structure,
      orderReference: getOrder.reference,
      applicationId: getOrder.order,
      loading: true,
      arrayInvalidSteps: [],
      demo: true,
      stepValidation: false,
    }
  }

  componentDidMount() {
    checkUtmParams();

    if (!(this.state.applicationId === undefined || this.state.applicationId === "") && this.state.pdfId === this.state.form.pdfId.value) {
      const orderHandler = new GetOrderHandler({})
      let form = this.state.form
      let structure = { ...this.state.structure }
      const data = {
        applicationId: this.state.applicationId,
      }
      this.setState({
        loading: true,
      })

      structure.steps.forEach(step => {
        step.validated = true
      })

      orderHandler.customAction(data).then(res => {
        Object.keys(res.form).forEach(objectKey => {
          if (form[objectKey]) {
            //Custom validation to add optional/mandatory fields when component is reloaded
            if (form[objectKey].name === "passportStatus") {
              if (form[objectKey].value !== "yes") {
                form["passportIssuanceDate"].required = false
                form["bookNumber"].required = false
                form["passportIssuanceDate"].validate = true
                form["bookNumber"].validate = true
                form["passportIssuanceDate"].label = form["passportIssuanceDate"].label.includes("Optional") ? form["passportIssuanceDate"].label : `${form["passportIssuanceDate"].label} (Optional)`
                form["bookNumber"].label = form["bookNumber"].label.includes("Optional") ? form["bookNumber"].label : `${form["bookNumber"].label} (Optional)`
              } else {
                form["passportIssuanceDate"].required = true
                form["bookNumber"].required = true
                form["passportIssuanceDate"].label = form["passportIssuanceDate"].label.includes("Optional") ? form["passportIssuanceDate"].label.replace("(Optional)", "") : `${form["passportIssuanceDate"].label} `
                form["bookNumber"].label = form["bookNumber"].label.includes("Optional") ? form["bookNumber"].label.replace("(Optional)", "") : `${form["bookNumber"].label} `
              }
            }
            if (form[objectKey].name === "passportCardStatus") {
              if (form[objectKey].value !== "yes") {
                form["cardIssuanceDate"].validate = true
                form["passportCardNumber"].validate = true
                form["cardIssuanceDate"].required = false
                form["passportCardNumber"].required = false
                form["cardIssuanceDate"].label = form["cardIssuanceDate"].label.includes("Optional") ? form["cardIssuanceDate"].label : `${form["cardIssuanceDate"].label} (Optional)`
                form["passportCardNumber"].label = form["passportCardNumber"].label.includes("Optional") ? form["passportCardNumber"].label : `${form["passportCardNumber"].label} (Optional)`
              } else {
                form["cardIssuanceDate"].required = true
                form["passportCardNumber"].required = true
                form["cardIssuanceDate"].label = form["cardIssuanceDate"].label.includes("Optional") ? form["cardIssuanceDate"].label.replace("(Optional)", "") : `${form["cardIssuanceDate"].label} `
                form["passportCardNumber"].label = form["passportCardNumber"].label.includes("Optional") ? form["passportCardNumber"].label.replace("(Optional)", "") : `${form["passportCardNumber"].label} `
              }
            }
            if (form[objectKey].name === "married") {
              if (form["married"].value === "no") {
                form["widowedDivorced"].value = ""
              }
            }
            if (objectKey !== "legalstructureId") {
              form[objectKey].value = res.form[objectKey]
              form[objectKey].validField = true
            }
          }
        })
        this.setState({
          form: form,
          loading: false,
          structure,
        })
      }).catch((body) => {
        this.setState({
          loading: false,
        })
      })
    } else {
      this.setState({
        loading: false,
      })
    }
  }

  editApplication = (step) => {
    this.setState({ loading: true })

    if (!this.stepValidator(step)) {
      this.setState({ loading: false })
      return false
    }

    let fields = []
    const stepsNumber = this.state.structure.steps.length - 1
    for (let i = 0; i < stepsNumber + 1; i++) {
      const step = this.state.structure.steps[i]
      const stepFields = this.getUsedFields(step)
      fields = fields.concat(stepFields)
    }
    let data = {}
    const form = this.state.form

    fields.map(field => {
      const formField = form[field]
      data[formField.name] = formField.value
    })
    data["pdfId"] = form["pdfId"].value

    const orderCookie = new EncodedCookieDataStore({
      cookieName: "address_token",
      decoderService: new Base64DecoderService(),
      encoderService: new Base64EncoderService(),
    })

    const editApplication = new EditApplicationHandler({})
    data.applicationId = this.state.applicationId
    editApplication.customAction(data).then(res => {

      const pdfName = form.pdfId.name
      window.location = "/review-" + pdfName
    }).catch((error) => {
      this.setState({ loading: false })
    })
  }
  submitApplication = (step) => {
    this.setState({ loading: true })

    if (!this.stepValidator(step)) {
      this.setState({ loading: false })
      return false
    }

    let fields = []
    const stepsNumber = this.state.structure.steps.length - 1
    for (let i = 0; i < stepsNumber + 1; i++) {
      const step = this.state.structure.steps[i]
      const stepFields = this.getUsedFields(step)
      fields = fields.concat(stepFields)
    }
    let data = {}
    const form = this.state.form
    fields.map(field => {
      const formField = form[field]
      data[formField.name] = formField.value
    })
    data["pdfId"] = form["pdfId"].value

    const orderCookie = new EncodedCookieDataStore({
      cookieName: "address_token",
      decoderService: new Base64DecoderService(),
      encoderService: new Base64EncoderService(),
    })

    const utmParams = (({utm_source, utm_campaign, utm_medium}) => ({
      utm_source,
      utm_campaign,
      utm_medium
    }))(this.state.cookie);

    Object.entries(utmParams).forEach(([utm, value]) => data[utm] = value);

    const orderHandler = new CreateOrderHandler({})
    orderHandler.customAction(data).then(res => {
      orderCookie.order = res.applicationId
      orderCookie.reference = res.reference
      orderCookie.pdfId = data.pdfId
      const pdfName = form.pdfId.name
      window.location = "/review-" + pdfName
    }).catch((error) => {
      this.setState({ loading: false })
    })
  }

  resetErrors = () => {
    this.setState({ arrayInvalidSteps: [] })
  }

  handleChange = e => {
    const target = e.target
    let value = target.value //Gathering the value of the event
    const name = target.name //Gathering the target of the event
    const tempForm = this.state.form
    const field = tempForm[name]
    if ((field.type === "select" || field.type === "date") && value !== "" && field.required) {
      field.validField = true
      field.validate = false
    }
    field.value = TypeSelector(field.component, value, target.checked) //This function sets the value of the field in the state, it's the one that's responsible for checking the type and set the value accordingly
    field.validate = true

    // Custom validations
    this.setState({ form: tempForm })
    switch(field.name) {
      case "passportStatus":
        if (field.value !== "yes") {
          tempForm["passportIssuanceDate"].required = false
          tempForm["bookNumber"].required = false
          tempForm["passportIssuanceDate"].validate = true
          tempForm["bookNumber"].validate = true
          tempForm["passportIssuanceDate"].label = tempForm["passportIssuanceDate"].label.includes("Optional") ? tempForm["passportIssuanceDate"].label : `${tempForm["passportIssuanceDate"].label} (Optional)`
          tempForm["bookNumber"].label = tempForm["bookNumber"].label.includes("Optional") ? tempForm["bookNumber"].label : `${tempForm["bookNumber"].label} (Optional)`
        } else {
          tempForm["passportIssuanceDate"].required = true
          tempForm["bookNumber"].required = true
          tempForm["passportIssuanceDate"].label = tempForm["passportIssuanceDate"].label.includes("Optional") ? tempForm["passportIssuanceDate"].label.replace("(Optional)", "") : `${tempForm["passportIssuanceDate"].label} `
          tempForm["bookNumber"].label = tempForm["bookNumber"].label.includes("Optional") ? tempForm["bookNumber"].label.replace("(Optional)", "") : `${tempForm["bookNumber"].label} `
        }
        break;
      case "passportCardStatus":
        if (field.value !== "yes") {
          tempForm["cardIssuanceDate"].validate = true
          tempForm["passportCardNumber"].validate = true
          tempForm["cardIssuanceDate"].required = false
          tempForm["passportCardNumber"].required = false
          tempForm["cardIssuanceDate"].label = tempForm["cardIssuanceDate"].label.includes("Optional") ? tempForm["cardIssuanceDate"].label : `${tempForm["cardIssuanceDate"].label} (Optional)`
          tempForm["passportCardNumber"].label = tempForm["passportCardNumber"].label.includes("Optional") ? tempForm["passportCardNumber"].label : `${tempForm["passportCardNumber"].label} (Optional)`
        } else {
          tempForm["cardIssuanceDate"].required = true
          tempForm["passportCardNumber"].required = true
          tempForm["cardIssuanceDate"].label = tempForm["cardIssuanceDate"].label.includes("Optional") ? tempForm["cardIssuanceDate"].label.replace("(Optional)", "") : `${tempForm["cardIssuanceDate"].label} `
          tempForm["passportCardNumber"].label = tempForm["passportCardNumber"].label.includes("Optional") ? tempForm["passportCardNumber"].label.replace("(Optional)", "") : `${tempForm["passportCardNumber"].label} `
        }
        break;
      case "married":
        if (tempForm["married"].value === "no") {
          tempForm["widowedDivorced"].value = "";
        }
        break;
      case "dateTrip":
        if (field.value) {
          tempForm["dateReturn"].minElegibleDate = field.value;
        }
        else tempForm["dateReturn"].minElegibleDate = "today";
        break;
      case "dateReturn":
        //Add a day to maxElegibleDate since the date of return can also be the date for beginning the trip.
        var maxDate = moment(field.value).add("1", "day").toString();
        tempForm["dateTrip"].maxElegibleDate = maxDate;
        break;
    }

    var makeFieldsRequired = false;
    var step = this.state.structure.steps.find((x) => x.name === "emergency-contact");
    const emergencyFields = step.groups[0].fields;
    if (emergencyFields.find((x) => x === field.name)) {
      emergencyFields.map(item => {
        if (tempForm[item].value != null && tempForm[item].value !== "") {
          makeFieldsRequired = true
        }
      })
      emergencyFields.map(item => {
        if (item !== "emergencyAddress2") {
          tempForm[item].validate = true
          tempForm[item].required = makeFieldsRequired
        }
      })
    }

    makeFieldsRequired = false;
    step = this.state.structure.steps.find((x) => x.name === "travel-plans");
    const travelFields = step.groups[0].fields;
    if (travelFields.find((x) => x === field.name)) {
      travelFields.map(item => {
        if (tempForm[item].value != null && tempForm[item].value !== "") {
          makeFieldsRequired = true;
        }
      });
      travelFields.map(item => {
        tempForm[item].validate = true;
        tempForm[item].required = makeFieldsRequired;
      });
    }
  }

  stepValidator = (step) => {
    const stepStructure = this.state.structure.steps[step]
    let stepVal = true
    const fields = this.getUsedFields(stepStructure)
    let firstField = ""
    fields.map(field => {
      let fieldValidation = this.fieldValidator(field)
      if (stepVal) {
        stepVal = stepVal && fieldValidation
        if (!stepVal && firstField == "") {
          firstField = field
        }
      }
    })
    this.scrollToRef(firstField)
    return stepVal
  }

  checkFieldRef = (field) => {
    let validation = true
    const data = this.state.form
    const ref = data[field].ref
    if (ref) {
      validation = data[ref.name].value == ref.value
      if (typeof ref.value == "boolean") {
        validation = data[ref.name].value !== ""
      }
    }
    let emptyField = false
    if (!(data[field].required)) {
      emptyField = data[field].value == ""
    }
    return validation ? validation !== emptyField : false
  }

  getUsedFields = (step) => {
    const fields = []
    const data = this.state.form
    let checkGroup = true
    step.groups.map(group => {
      if (group.ref) {
        const ref = group.ref
        checkGroup = data[ref.name].value == ref.value
      }
      if (checkGroup) {
        group.fields.map(field => {
          const index = !(fields.indexOf(field) > -1)
          const checkField = this.checkFieldRef(field)
          if (index && checkField) {
            fields.push(field)
          }
        })
      } else {
        checkGroup = true
      }
    })
    return fields
  }


  fieldValidator = (fieldName) => {
    let form = this.state.form
    let field = form[fieldName]
    let required = form[fieldName].required
    const validation = required ? TypeSelectorValidate(field) == required : true
    form[fieldName].validate = validation
    form[fieldName].validField = validation
    this.setState({
      form: form,
    })

    return validation
  }

  scrollToRef = ref => {
    if (document.getElementById(ref)) {
      window.scrollTo({
        top: document.getElementById(ref).getBoundingClientRect().top + window.scrollY - 110,
        behavior: "smooth",
      })
    }
  }

  render() {
    let stepNames = []
    this.props.json.structure.steps.map(step => {
      let stepName = step.label.split(".")[1]
      stepNames.push(stepName)
    })
    let custom = {
      enterRight: "0",
      enterLeft: "0",
      exitRight: "0",
      exitLeft: "0",
    }
    return (
      this.state.loading ?
        <Loading/>
        :
        <div>
          <StepWizard nav={<Nav stepNames={stepNames}/>}
                      isLazyMount={true}
                      isHashEnabled={true}
                      transitions={custom}>
            {this.props.json.structure.steps.map((step) => {
              return (
                <MainFormSteps
                  key={step.name}
                  resetErrors={this.resetErrors}
                  invalidSteps={this.state.arrayInvalidSteps}
                  hashKey={step.name}
                  handleChange={this.handleChange}
                  validation={this.fieldValidator}
                  applicationId={this.state.applicationId}
                  json={this.props.json}
                  form={this.state.form}
                  submitApplication={this.submitApplication}
                  editApplication={this.editApplication}
                  stepValidator={this.stepValidator}
                />
              )
            })}
          </StepWizard>
        </div>
    )
  }
}

export default MultiStepForm
