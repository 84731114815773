import React from "react"
import './tooltip.scss'
import ReactTooltip from 'react-tooltip'


const Tooltip = ( {content, position, effect, event, tip, dataFor} ) => {
    return (
            <>
            <span data-effect={effect} data-place={position} data-event={event} data-tip={tip} data-for={dataFor} className="m-tooltip m-tooltip__CCV">i</span>
            <ReactTooltip  globalEventOff='click' id={dataFor} className='m-tooltip--content' aria-haspopup='true' role='tooltip'>
                <p>{content}</p>
            </ReactTooltip>
            </>
    )
};


export default Tooltip

