import React from "react"
import { refHandler } from "../../../services/componentSwitcher/ComponentSwitcher"
import FormComponents from "../FormComponents"
import "./MainForm.scss"
import { Loading } from "../../Modules/Loading/loading"
import { Button } from "../../Layout/Buttons/buttons"
import Wrapper from "../../Layout/Wrapper/wrapper"
import moment from "moment"
import disclaimer from "../../../images/svg/icon-info-orange.svg"

export default class MainFormSteps extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      structure: this.props.json.structure,
      loading: true,
      stepKey: this.props.currentStep - 1,
      invalidSteps: this.props.invalidSteps,
      allowedSteps: [],
      previousPath: ''
    }
  }

  componentDidMount() {
    if (document !== undefined) this.setState({ previousPath: document.referrer })
    const step = this.state.stepKey
    if(step > 0){
      if((!this.props.stepValidator(step -1)) || (!this.props.stepValidator(0))){
        window.location = window && window.location.pathname
      }
    }
    this.setState({
      loading : false
    })
  }

  nextStep = () => {
    if (this.props.stepValidator(this.state.stepKey)) {
      this.props.nextStep()
    }
  }

  previousStep = () => {
    this.props.previousStep()
  }

  goToClean = (step) => {
    this.props.resetErrors()
    this.props.goToStep(step)
  }

  render() {
    const step = this.state.structure.steps[this.state.stepKey]
    return (
      <div>
        <form onSubmit={this.onSubmit} className="m-form">
          {this.state.loading &&
          <Loading/>
          }
          <div className="m-form-container">
            <div className={`m-form-step ${step.name}`} >
              {step.label !== "" &&
              <h1 className='extra-large'>{step.label}</h1>
              }
              {step.groups.map((group, index) => {
                return (
                  <div className={`group-${index} ${group.name}-wrapper`} key={index + group.name}>
                    {group.disclaimer &&
                    <div className={`group-disclaimer small`}><img src={disclaimer}/>{group.disclaimer}</div>}
                    {refHandler(group, this.props.form) &&
                    <div className={`m-form-group ${group.class}`}>
                      {group.label && <p className="group-title big">{group.label}</p>}
                      {group.subLabel && <p className="group-subtitle medium">{group.subLabel}</p>}
                      {group.fields.map(item => {
                        const field = this.props.form[item]
                        const FieldComponent = FormComponents[field.component]
                        return (
                          <React.Fragment key={item}>
                            {refHandler(field, this.props.form) &&
                              <FieldComponent 
                                  onChange={(e) => this.props.handleChange(e)} 
                                  onBlur={(e) => this.props.validation(e.target.name)} 
                                  {...field}
                                  />
                            }
                          </React.Fragment>
                        )
                      })}
                    </div>
                    }
                  </div>
                )
              })}
            </div>

          </div>
        </form>
        <Wrapper isFlex classNames={`buttons_wrapper`}>
          {this.props.currentStep > 1 && !this.state.previousPath.includes("review") &&
          <Wrapper classNames={`back`}>
            <Button secondary
                    label={`Back`} nolink onClick={this.previousStep}
                    functionParameters=''/>
          </Wrapper>
          }
          {this.props.currentStep < this.props.totalSteps && !this.state.previousPath.includes("review") &&
          <Wrapper classNames={`next`}>
            <Button primaryAlt label={`Next Step`}
                    nolink onClick={this.nextStep}
                    functionParameters=''/>
          </Wrapper>
          }
          {this.props.currentStep <= this.props.totalSteps && this.state.previousPath.includes("review") &&
          <Wrapper half classNames={`save-changes next`}>
            <Button primaryAlt label={`Save changes`}
                    nolink 
                    onClick={(e) => this.props.editApplication(this.state.stepKey)}
                    functionParameters=''/>
          </Wrapper>
          }
          {this.props.currentStep === this.props.totalSteps && !this.state.previousPath.includes("review") &&
          <Wrapper half classNames={`submit next`}>
            <Button primary label={`Complete Application`} 
                    nolink 
                    onClick={(e) => this.props.submitApplication(this.state.stepKey)}
                    functionParameters=''/>
          </Wrapper>
          }
        </Wrapper>
        <Wrapper>
          {this.props.invalidSteps.length > 0 && this.props.invalidSteps.map(invalidStep => {
            invalidStep++
            return (
              <div key={invalidStep}>
                Looks like you didn't complete the step {invalidStep}.
                <Button secondary label={`Check Order Status`} nolink onClick={this.goToClean}
                        functionParameters={invalidStep}/>
              </div>
            )
          })}
        </Wrapper>

      </div>
    )

  }
}
