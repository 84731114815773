import React from "react"
import "./textInput.scss"
import InputMask from "react-input-mask"
import Tooltip from "../Tooltip/tooltip"

const TextInput = props => {
  const classStyle = `${props.validate ? "e-text" : "e-text is-invalid"} ${props.class ? props.class : ""} e-${props.type} ${props.validField ? "is-valid" : ""}`
  const dataQA = `${props.type}-${props.name}`
  return (
    <div className={classStyle}>
      <span className="e-text-error">{props.errorMessage}</span>
      <input className="e-text-input"
             type={props.type}
             name={props.name}
             id={props.name}
             maxLength={props.maxlength}
             minLength={props.minlength}
             placeholder={props.placeholder}
             onChange={props.onChange}
             onBlur={props.onBlur}
             value={props.value}
             data-qa={dataQA}
      />
      <div className="inputLabel">
        <p>{props.label}</p>
        {props.notes && <p className="inputNotes">{props.notes}</p>}
      </div>

      {props.tooltip &&
      <Tooltip
        position="left"
        effect="solid"
        event="click"
        tip={props.name}
        dataFor={`m-tooltip__${props.name}`}
        content={props.tooltip}/>
      }
    </div>
  )
}

export default TextInput

export const InputMaskForm = props => {
  const dataQA = `${props.type}-${props.name}`
  const classStyle = `${props.validate ? "e-text" : "e-text is-invalid"} ${props.class ? props.class : ""} e-${props.type} ${props.validField ? "is-valid" : ""}`

  return (
    <div className={classStyle}>
      <span className="e-text-error">{props.errorMessage}</span>
      <InputMask
        placeholder={props.placeholder}
        name={props.name}
        className="e-text-input"
        mask={props.mask}
        id={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        maskChar={null}
        value={props.value}
        data-qa={dataQA}
      />
      <div className="inputLabel">{props.label}</div>
      {props.tooltip &&
      <Tooltip
        position="left"
        effect="solid"
        event="click"
        tip="CVV"
        dataFor={`m-tooltip__${props.name}`}
        content={props.tooltip}/>
      }
      {props.help &&
        <div className="e-text-help">
          {props.help}
        </div>
      }
    </div>
  )
}

export const ExpirationDate = props => {
  const classStyle = `e-text m-input__expirationDate ${props.class ? props.class : ""}`
  return (
    <div className={classStyle}>
      <div className="m-input--footer">
        {props.validate_month &&
        <span className="e-text-error">{props.error.month}</span>
        }
        {props.validate_year &&
        <span className="e-text-error">{props.error.year}</span>
        }
      </div>
      <div className="m-input--content">
        <div className={props.validate_month ? "e-text" : "e-text is-invalid"}>
          <InputMaskForm
            className="e-text-input"
            type="text"
            mask="99"
            maxlength="2"
            name="card_expiration_month"
            placeholder={props.placeholder_month}
            onChange={props.onChange}
            value={props.value_month ? props.value_month : ""}
          />
        </div>
        <div className={props.validate_year ? "e-text" : "e-text is-invalid"}>
          <input
            className="e-text-input"
            type="text"
            name="card_expiration_year"
            placeholder={props.placeholder_year}
            onChange={props.onChange}
            value={props.value_year ? props.value_year : ""}
          />
        </div>
      </div>
      <div className="m-input--header">
        <div className="m-input-title">{props.title}</div>
      </div>
    </div>
  )
}
